<template lang="pug">
.date-filter-card
  .date-filter-card__range(v-if="showRange && !defaultRange?.dateTo && !defaultRange?.dateFrom")
    .date-filter-card__range-title(
      v-for="range in defaultRanges"
      :key="`${range.from}-${range.to}`"
      @click="updateFormDateButton(dateType, range.from, range.to, range.f)"
    ) {{ range.title }}
  .date-filter-card__wrapper
    date-picker.date-filter-card__datepicker(
      v-model="dates[0]"
      :teleported="false"
      :placeholder="defaultRange?.dateFrom ? `От ${ formatDateTime(defaultRange?.dateFrom) }` : 'От'"
      :disabled-date="disabledDate"
      @change="onChange($event, 'dateFrom')"
    )
    date-picker.date-filter-card__datepicker(
      v-model="dates[1]"
      :teleported="false"
      :placeholder="defaultRange?.dateTo ? `До ${ formatDateTime(defaultRange?.dateTo) }` : 'До'"
      :disabled-date="disabledDate"
      @change="onChange($event, 'dateTo')"
    )
</template>

<script lang="ts">
import { ref, defineComponent, watch, toRefs } from "vue";
import { formatDateTime } from "@/utils/formatter/dateFormatter";

import type { PropType } from "vue";
import type { DateFilterI } from "~/stores/search/SearchFormInterface";
import DatePicker from "@/components/ui/picker/DatePicker.vue";

type DateType = "dateApplication" | "datePublic" | "dateAuction" | "dateConclusion" | "dateRegulations" | "dateCreate" | "dateEndTariff";

export default defineComponent({
  name: "DateFilterCard",
  methods: {
    formatDateTime,
  },
  components: {
    DatePicker,
  },
  props: {
    showRange: {
      type: Boolean,
      default: true,
    },
    dateValue: {
      type: Object,
      default: () => ({})
    },
    dateType: {
      type: String as PropType<DateType>,
      required: true,
    },
    futureRanges: {
      type: Boolean,
      default: false,
    },
    defaultRange: {
      type: Object as PropType<DateFilterI>,
      default: () => ({
        dateFrom: undefined,
        dateTo: undefined,
      }),
    }
  },
  emits: [
    "update:date",
  ],
  setup(props, context) {

    const lastRanges = [
      { title: 'Вчера', from: -1, to: -1, f: getRange },
      { title: 'Сегодня', from: 0, to: 0, f: getRange },
      { title: 'Прошлый год', from: -1, to: -1, f: getYearRange },
      { title: 'Прошлый месяц', from: -1, to: 0, f: getMonthRange },
      { title: 'Текущий год', from: 0, to: 0, f: getYearRange },
      { title: 'Текущий месяц', from: 0, to: 1, f: getMonthRange },
    ]

    const futureRanges = [
      { title: 'Сегодня', from: 0, to: 0, f: getRange },
      { title: 'Завтра', from: 1, to: 1, f: getRange },
      { title: 'Следующие 3 дня', from: 1, to: 4, f: getRange },
      { title: 'Следующая неделя', from: 1, to: 8, f: getRange },
      { title: 'Следующие 3 недели', from: 1, to: 22, f: getRange },
      { title: 'Следующий месяц', from: 1, to: null, f: nextMonth },
    ]

    const { dateValue } = toRefs(props);
    const defaultRanges = props.futureRanges ? futureRanges : lastRanges;

    function geTime() {
      return [
        dateValue?.value?.dateFrom ? new Date(dateValue?.value.dateFrom) : null,
        dateValue?.value?.dateTo ? new Date(dateValue?.value.dateTo) : null,
      ];
    }

    const dates = ref(geTime());

    watch(dateValue, () => {
      dates.value = geTime();
    });

    function nextMonth(start: number, end: number) {
      const date = new Date();
      const from = new Date(new Date(new Date().setDate(date.getDate() + start)).setHours(0,0,0,0,));
      return {
        from: from,
        to: new Date(new Date(from).setMonth(from.getMonth() + 1))
      }
    }

    function getYearRange(start: number, end: number) {
      const date = new Date();
      return {
        from: new Date(date.getFullYear() + start, 0, 1),
        to: new Date(date.getFullYear() + end, 12, 0, 23, 59, 59),
      }
    }

    function getMonthRange(start: number, end: number) {
      const date = new Date();
      return {
        from: new Date(date.getFullYear(), date.getMonth() + start, 1),
        to: new Date(date.getFullYear(), date.getMonth() + end, 0, 23, 59, 59),
      }
    }

    function getRange(start: number, end: number) {
      const date = new Date();
      return {
        from: new Date(new Date(new Date().setDate(date.getDate() + start)).setHours(0,0,0,0,)),
        to: new Date(new Date(new Date().setDate(date.getDate() + end)).setHours(23,59,59,0,)),
      }
    }

    function updateFormDateButton(type: DateType, from: number, to: number, getDateFunc: Function) {
      const payload = {
        field: type,
        value: {
          dateFrom: getDateFunc(from, to).from,
          dateTo: getDateFunc(from, to).to,
        },
      };
      context.emit("update:date", payload);
    }

    function onChange(event: Date | null, mode: 'dateFrom'|'dateTo') {
      let value;
      if ((mode === 'dateFrom' && dateValue?.value?.dateTo && event && new Date(event) > new Date(dateValue?.value?.dateTo))
        || (mode === 'dateTo' && dateValue?.value?.dateFrom && event && new Date(event) < new Date(dateValue?.value?.dateFrom))) {
        value = {
          dateFrom: event,
          dateTo: new Date(new Date(event).setHours(23, 59, 59)),
        }
      } else {
        value = {
          dateFrom: mode === 'dateFrom' ? event : dateValue?.value?.dateFrom,
          dateTo: mode === 'dateTo' ? (event ? new Date(new Date(event).setHours(23, 59, 59)) : event) : dateValue?.value?.dateTo,
        }
      }

      context.emit("update:date", {
        field: props.dateType,
        value: value,
      });
    }

    function disabledDate(time: Date) {
      return (props.defaultRange.dateFrom && new Date(time).getTime() < new Date(props.defaultRange.dateFrom)?.getTime())
      || (props.defaultRange.dateTo && new Date(time).getTime() > new Date(props.defaultRange.dateTo)?.getTime())
    }

    return {
      dates,
      defaultRanges,
      disabledDate,
      onChange,
      updateFormDateButton,
    };
  },
});
</script>

<style scoped lang="scss">
.date-filter-card {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: var(--max-advanced-filters-width);
  gap: 16px;
  flex-flow: column;

  .date-filter-card__range {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    grid-auto-flow: column;
    column-gap: 16px;
    row-gap: 4px;
  }

  .date-filter-card__range-title {
    color: var(--main-color-blue);
    cursor: pointer;
    font-size: 13px;
  }

  .date-filter-card__wrapper {
    display: flex;
    justify-content: center;
    gap: 8px;

    .date-filter-card__datepicker {
      width: 100%;
    }
  }
}
</style>
